import React, { useEffect, useState } from "react";
import { editPersonnel, getPersonnelById, updatepersonnel } from "../../Services/Pointeuse/PersonnelApi";
import { getPersonnelDocByUserId } from "../../Services/Pointeuse/PersonnelDocApi";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorCustum from "../Others/ErrorCustum";
import swal from "sweetalert";
import { t } from "i18next";
import { handleKeyPress_number } from "../../Utils/InputUtils";
import { getAllDepartements, getDepartementByUser } from "../../Services/Pointeuse/DepartementApi";
import Select from 'react-select';
import { get_rub_user, getparam_paie_active, getparam_rub_paie_active } from "../../Services/paie/parametrage_paie";
import moment from "moment";
import { toast } from "react-toastify";
import { getAvantage_nature_active, getAvantage_nature_user, getExercice, getType_contrat } from "../../Services/Pointeuse/EntrepriseApi";
import Avantage_fiscaux from "./CardPersoComponents/Avantage_fiscaux";
import { arondisnumber, float_decimale, formatNChiffres } from "../../Utils/helper";

function CardPerso({ userId,userdata,setuserdata,types_contrat, settypes_contrat }) {
  const list_situation_familiale = ['marié', 'divorce', 'separe', 'celibataire', 'veuf'];
  const mode_paiement = ['cache', 'cheque', 'virement'];
  const list_fonction = ['gerant', 'dev']


 
  const [list_rubriques, setlist_rubriques] = useState([])
  const [alllist_av_fiscaux, setalllist_av_fiscaux] = useState([])
  const [list_av_fiscaux, setlist_av_fiscaux] = useState([])
  const [list_avantages, setlist_avantages] = useState([])
  const [list_avantages_user, setlist_avantages_user] = useState([])

  const [data, setdata] = useState([]);
  const [add_av_fiscaux, setadd_av_fiscaux] = useState(false)
  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [user, setuser] = useState(null);
  const [img, setimg] = useState("");

  const [alldep, setalldep] = useState([]);
  const [allrubriques, setallrubriques] = useState([]);
  const [param_defaut, setparam_defaut] = useState();
  const [nbr_salire_alert, setnbr_salire_alert] = useState(null);
  const [calandrier_msg, setcalandrier_msg] = useState(false);
  const [exercices, setexercices] = useState([])
  const [exercices_defaut, setexercices_defaut] = useState()

  useEffect(() => {
    getAllDepartements().then((res) => {
      let newlt = []
      res.data.forEach(element => {
        const datadep = { value: element.code, label: element.nom };
        newlt.push(datadep)
      });
      setalldep(newlt)
    })
    getparam_paie_active().then((res) => {

      setparam_defaut(res.data.data[0])

    })
  }, [])
  useEffect(() => {
    getExercice().then((res) => {
      setexercices(res.data);

    });
  }, [])
  useEffect(() => {
    console.log("param_defaut",param_defaut)

    if (param_defaut) {
      setLoading(true);
      getPersonnelById(userId).then((res) => {
        console.log("res",res)
        getDepartementByUser(res.data.id).then((resultat) => {
          console.log("resresultat",resultat)

          let newlist = []

          resultat.data.data.forEach(element => {
            const datadep = { value: element.code, label: element.nom };
            newlist.push(datadep)
          });
          let jsonObject;

          if (res.data.calandrier !== null && res.data.calandrier !== "") {
            jsonObject = JSON.parse(res.data.calandrier);

            const convertedObject = {};
            for (const key in jsonObject) {
              const newKey = key.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Remove diacritics
              convertedObject[newKey] = jsonObject[key];
            }

          } else {
            jsonObject = JSON.parse(param_defaut.calandrier);

            const convertedObject = {};
            for (const key in jsonObject) {
              const newKey = key.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Remove diacritics
              convertedObject[newKey] = jsonObject[key];
            }
          }
          setuserdata((prev) => ({
            ...prev,
            id: res.data.id,
            user_code_id: res.data.user_code_id,
            cin: res.data.cin,
            delivre_a:res.data.delivre_a,
            fonction:res.data.fonction,
            delivre_le: res.data.delivre_le,
            cnss: res.data.cnss,
            taux_cnss: res.data.taux_cnss !== null ? res.data.taux_cnss : param_defaut?.val_cnss,
            type_contrat: res.data.type_contrat,
            date_embauche: res.data.date_embauche,
            date_fincontrat: res.data.date_fincontrat,
            famille: res.data.famille,
            enfant: res.data.enfant,
            adresse: res.data.adresse,
            salaire_base: res.data.salaire_base,
            rib: res.data.rib,
            bank: res.data.bank,
            agence: res.data.agence,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            contact_urgence: res.data.contact_urgence,
            resident: res.data.resident === null ? 'oui' : res.data.resident,
            num_passport: res.data.num_passport,
            assurance_groupe: res.data.assurance_groupe !== null ? res.data.assurance_groupe : param_defaut?.assurance_G,
            val_assurance_groupe: res.data.assurance_groupe !== null ? res.data.val_assurance_groupe : param_defaut?.val_assurance_G,
            salaire_brut: res.data.salaire_brut,
            salaire_net: res.data.salaire_net,
            Parent_charge: res.data.Parent_charge,
            enfant_handicape: res.data.enfant_handicape,
            mode_paiement: res.data.mode_paiement,
            enfant_N_B: res.data.enfant_N_B,
            situation_familiale: res.data.situation_familiale,
            num_carte_sejour: res.data.num_carte_sejour,
            departement: newlist,
            nbr_salaire: (res.data.nbr_salaire !== 0 && res.data.nbr_salaire !== null) ? res.data.nbr_salaire : param_defaut?.nb_salaire,
            nb_jr_mois: res.data.nb_jr_mois,
            nbr_heure_mois: res.data.nbr_heure_mois,
            chef_famille: res.data.chef_famille,
            calandrier: jsonObject,
            matricule_user:res.data.matricule_user

          }))
        })


        setdata(res.data);



      });
      setLoading(false);
    }

  }, [param_defaut]);
  useEffect(() => {
    if (exercices && param_defaut) {

      const find_exercice_defaut = exercices.find(item => item.id === param_defaut.exercice)
      if (find_exercice_defaut) setexercices_defaut(find_exercice_defaut)
    }
  }, [param_defaut, exercices])
  useEffect(() => {
    getPersonnelDocByUserId(userId)
      .then((resDocument) => {
        setDocumentData(resDocument.data);
      })
      .finally(() => setLoading(false));
  }, [])
  useEffect(() => {
    if (userId && allrubriques.length !== 0) {

      get_rub_user(userId).then((res) => {
        const list = res.data?.map((item) => {
          const copie = { ...item }
          const resultat = [...allrubriques, ...alllist_av_fiscaux];

          const findrub = resultat.find(e => e.code === item.rub_code)
          if (findrub) {
            copie.type = findrub.type;
            copie.presence = findrub.presence

          }
          return copie
        })
        const list_rub = list.filter(item => item.type !== 'avantage_fiscaux')
        const list_av_fiscaux = list.filter(item => item.type === 'avantage_fiscaux');

        if (list_av_fiscaux.length !== 0) {
          setlist_av_fiscaux(list_av_fiscaux)

          setadd_av_fiscaux(true)
        }
        setlist_rubriques(list_rub)
      })
      getAvantage_nature_user(userId).then((res) => {
        setlist_avantages_user(res.data)
      })
    }


  }, [userId, allrubriques, alllist_av_fiscaux])

  useEffect(() => {
    getparam_rub_paie_active().then((res) => {
      if (res?.data?.data && Array.isArray(res.data.data)) {
        const list_rub = res.data.data.filter(item => item.type !== 'avantage_fiscaux')
        const list_av_fiscaux = res.data.data?.filter(item => item.type === 'avantage_fiscaux')
        setallrubriques(list_rub);
        setalllist_av_fiscaux(list_av_fiscaux)
      }

    });
    getType_contrat().then((res) => {

      settypes_contrat(res.data)


    })
    getAvantage_nature_active().then((res) => {
      setlist_avantages(res.data.data)
    })
  }, [])
  /**
   * * This function is used to update personnel data in the database
   */
  const handleSubmitPersonnel = () => {
    if (userdata.cin === null ||
      userdata.delivre_le === null) {
      toast.warning(t('Vous devez remplir tous les champs obligatoires'))
    }else {
      swal({
        title: "Modification",
        text: "Voulez vous vraiment modifier cet enregistrement!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const copie = { ...userdata }
          delete copie.departement
          copie.calandrier = JSON.stringify(userdata.calandrier);

          const deps_codes = userdata.departement.map(obj => obj.value);
          const lists = [...list_rubriques, ...list_av_fiscaux];

          const list_rub = lists.map(item => {
            const element = { ...item }
            delete element.type;
            delete element.presence
            element.date_debut = element.date_debut.split('T')[0]
            element.date_fin = element.date_fin.split('T')[0]
            if (element.created_at) element.created_at = element?.created_at.split('T')[0]

            return element
          }

          )
          const list_avantages = list_avantages_user.map(item => {
            const element = { ...item }
            delete element.type; delete element.presence
            element.date_debut = element.date_debut.split('T')[0]
            element.date_fin = element.date_fin.split('T')[0]
            if (element.created_at) element.created_at = element?.created_at.split('T')[0]

            return element
          }

          )



          const data_send = { user: copie, deps: deps_codes, code_generated: localStorage.getItem('code_generated'), rub: list_rub, user_code: userId, listavantages: list_avantages }

          const findnonrub = list_rubriques.find(item => item.rub_code === '');
          if (findnonrub) {
            toast.warning(t('choisir rubrique pout tout les lignes'))
          } else {
            updatepersonnel(
              data_send
            ).then((res) => {

              if (res.status === 200) {
                swal("Opération effectuée avec succès!", {
                  icon: "success",
                });
              }

            });
          }

        }
      });
    }



  };
  // const handleSubmitPersonnel = () => {
  //   console.log(famille);
  // };

  useEffect(() => {
    getUserById(userId).then((res) => {
      if (res.data.img) {
        setimg(localStorage.getItem("baseUrl") + res.data.img);
      } else {
        setimg("/assets/images/ic_man.png");
      }

      setuser(res.data);
    });
  }, []);


  const handlechange = (name, value) => {
    const copie_data = { ...userdata }
    if (name === 'departement') {
      copie_data.departement = value
    }
    else if (name === 'type_contrat') {
     // copie_data.cnss = null;
      copie_data.taux_cnss = types_contrat.find(item => item.code === value)?.cnss === 1 ? param_defaut?.val_cnss : null;
      copie_data[name] = value;

    } else if (name === 'fonction') {

      copie_data.taux_cnss = value !== 'gerant' ? param_defaut?.val_cnss : null;
     
      copie_data[name] = value;
    }


    else {
        
      if (name === 'nbr_salaire') {
        if (parseFloat(value) !== param_defaut.nb_salaire) {
          setnbr_salire_alert(t('le nombre de  salaire de societe ' + param_defaut.nb_salaire))

        } else {
          setnbr_salire_alert(null)
        }


      }
      if (name === 'salaire_base') {
        const total_indemnite = list_rubriques.filter(item => item.type === 'indemnite').reduce((acc, curr) => {
          if (curr.presence === 1) {
            if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
              return acc + (curr.valeur_mensuel * userdata.calandrier.Janvier);

            } else {
              return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3) * userdata.calandrier.Janvier);
            }

          } else {
            if (curr.valeur_annuel && curr.valeur_mensuel !== 0) {
              return acc + (curr.valeur_mensuel);
            } else {
              return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3));
            }
          };

        }, 0) || 0;
        const total_av = list_avantages_user.reduce((acc, curr) => {
          if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
            return acc + (curr.valeur_mensuel);

          } else {
            return acc + (formatNChiffres((curr.valeur_annuel / data.nbr_salaire), 3));
          }

        }, 0) || 0;
        copie_data.salaire_brut = formatNChiffres((parseFloat(value) + total_indemnite + total_av), 3)
      }


      else if (name === 'salaire_brut') {
        const total_indemnite = list_rubriques.filter(item => item.type === 'indemnite').reduce((acc, curr) => {
          if (curr.presence === 1) {
            if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
              return acc + (curr.valeur_mensuel * userdata.calandrier.Janvier);

            } else {
              return acc + ((curr.valeur_annuel / userdata.nbr_salaire) * userdata.calandrier.Janvier);
            }

          } else {

            if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
              return acc + (curr.valeur_mensuel);
            } else {
              return acc + ((curr.valeur_annuel / userdata.nbr_salaire));

            }
          };

        }, 0) || 0;
        const total_av = list_avantages_user.reduce((acc, curr) => {
          if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
            return acc + (curr.valeur_mensuel);

          } else {
            return acc + (formatNChiffres((curr.valeur_annuel / data.nbr_salaire), 3));
          }

        }, 0) || 0;
        copie_data.salaire_base = formatNChiffres((parseFloat(value) - (total_av + total_indemnite)), 3)
      }
      else if (name === 'situation_familiale' && value === 'celibataire') {
        copie_data.enfant = 0;
        copie_data.chef_famille = 0;
        copie_data.enfant_handicape = 0;
        copie_data.enfant_N_B = 0;
      }
      const updatedValue = value.replace(/,/g, '.');

      copie_data[name] = updatedValue;

    }

    setuserdata(copie_data)
  }
  const handleAddClick = () => {
    setlist_rubriques([
      ...list_rubriques,
      {
        user_code: userId,
        code_generated: localStorage.getItem('code_generated'),
        rub_code: '',
        permanent: 0,
        date_debut: moment(new Date()).format("YYYY-MM-DD"),
        date_fin: moment(new Date(`12-31-${exercices_defaut?.code_exercice}`)).format("YYYY-MM-DD"),
        exercice: param_defaut.exercice,
        valeur_annuel: 0,
        valeur_mensuel: 0,
        type: ''

      }
    ]);
  }
  const handleAddClick_avantage = () => {
    setlist_avantages_user([
      ...list_avantages_user,
      {
        user_code: userId,
        code_generated: localStorage.getItem('code_generated'),
        avantage_code: '',
        exercice: param_defaut.exercice,
        permanent: 1,
        date_debut: moment(new Date()).format("YYYY-MM-DD"),
        date_fin: moment(new Date(`12-31-${exercices_defaut?.code_exercice}`)).format("YYYY-MM-DD"),
        valeur_annuel: 0,
        valeur_mensuel: 0


      }
    ]);
  }
  const handleRemoveClick = (index) => {
    const list = [...list_rubriques];
    list.splice(index, 1);
    setlist_rubriques(list);
  };
  const handleRemoveClick_avantage = (index) => {
    const list = [...list_avantages_user];
    list.splice(index, 1);
    setlist_avantages_user(list);
  };

  const handlechangligne = (e, index) => {
    const { name, value } = e.target;

    const list = [...list_rubriques];

    if (name === 'permanent') {
      list[index].permanent = list[index].permanent === 1 ? 0 : 1
    }
    else if (name === 'rub_code') {
      const find_same = list_rubriques.find(item => item.rub_code === value)
      if (find_same) {
        toast.warning(t('cette avantage deja selectionné '))
      } else {
        const findrub = allrubriques.find(item => item.code === value);
        if (findrub) {

          list[index].valeur_mensuel = findrub.plafond === 1 ? findrub.montant_plafond : 0
          list[index].valeur_annuel = findrub.plafond === 1 ? findrub.montant_plafond : 0
          list[index].permanent = findrub.type === 'avantage_fiscaux' ? 0 : list[index].permanent
          list[index].type = findrub.type
          list[index].presence = findrub.presence

        }
        list[index][name] = value

      }
    }
    else if (name === 'valeur_mensuel' || name === 'valeur_annuel') {


      list[index][name] = float_decimale(value) || 0;
      // Vérifiez si la valeur est un nombre valide ou se termine par un point


      const total_indemnite = list.filter(item => item.type === 'indemnite').reduce((acc, curr) => {
        if (curr.presence === 1) {
          if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
            return acc + (curr.valeur_mensuel * userdata.calandrier.Janvier);

          } else {
            return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3) * userdata.calandrier.Janvier);
          }

        } else {

          if (curr.valeur_mensuel !== 0) {

            return acc + (curr.valeur_mensuel);
          } else {

            return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3));
          }
        };

      }, 0) || 0;

      const total_av = list_avantages_user.reduce((acc, curr) => {
        if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
          return acc + (curr.valeur_mensuel);

        } else {
          return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3));
        }

      }, 0) || 0;

      setuserdata((prev) => ({
        ...prev,
        salaire_brut: arondisnumber(parseFloat(userdata.salaire_base) + total_av + total_indemnite, 3)
      }))
    }
    else {
      list[index][name] = value

    }
    setlist_rubriques(list)



  }
  const handlechangligne_avantage = (e, index) => {
    const { name, value } = e.target;

    const list = [...list_avantages_user];

    if (name === 'permanent') {
      list[index].permanent = list[index].permanent === 1 ? 0 : 1
    }
    else if (name === 'avantage_code') {
      const find_same = list_avantages_user.find(item => item.avantage_code === value)
      if (find_same) {
        toast.warning(t('cette avantage deja selectionné '))
      } else {
        list[index][name] = value


      }
    }
    else if (name === 'valeur_mensuel' || name === 'valeur_annuel') {
      list[index][name] = float_decimale(value) || 0



      const total_av = list.reduce((acc, curr) => {
        if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
          return acc + curr.valeur_mensuel;

        } else {
          return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3));
        }

      }, 0) || 0;

      const total_indemnite = list_rubriques.filter(item => item.type === 'indemnite').reduce((acc, curr) => {
        if (curr.presence === 1) {
          if (curr.valeur_mensuel && curr.valeur_mensuel !== 0) {
            return acc + (curr.valeur_mensuel * userdata.calandrier.Janvier);

          } else {
            return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3) * userdata.calandrier.Janvier);
          }

        } else {
          if (curr.valeur_annuel && curr.valeur_mensuel !== 0) {
            return acc + (curr.valeur_mensuel);
          } else {
            return acc + (formatNChiffres((curr.valeur_annuel / userdata.nbr_salaire), 3));
          }
        };

      }, 0) || 0;

      setuserdata((prev) => ({
        ...prev,
        salaire_brut: arondisnumber(parseFloat(userdata.salaire_base) + total_av + total_indemnite, 3)

      }))
    }
    else {
      list[index][name] = value

    }
    setlist_avantages_user(list)


  }
  const handelchange_calandrier = (key, value) => {

    if (key === 'Janvier') {
      setcalandrier_msg(true)
    }
    setuserdata(prev => ({
      ...prev,
      calandrier: {
        ...prev.calandrier,
        [key]: value || 0
      }
    }));


  }
  const remplirCalandrier = () => {
    setuserdata(prev => ({
      ...prev,
      calandrier: {
        ...prev.calandrier,
        février: userdata.calandrier.Janvier,
        mars: userdata.calandrier.Janvier,
        avril: userdata.calandrier.Janvier,
        mai: userdata.calandrier.Janvier,
        juin: userdata.calandrier.Janvier,
        juillet: userdata.calandrier.Janvier,
        août: userdata.calandrier.Janvier,
        septembre: userdata.calandrier.Janvier,
        octobre: userdata.calandrier.Janvier,
        novembre: userdata.calandrier.Janvier,
        décembre: userdata.calandrier.Janvier,

      }
    })); setcalandrier_msg(false)

  }
  const preventTyping = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {loading && <CustomSyncLoader></CustomSyncLoader>}
      {!loading && error && <ErrorCustum></ErrorCustum>}
      {!loading && !error && (
        <div className="card-body">
          <form>
            {/* <h4 className="mb-4 font-weight-bold">Basic</h4> */}
            <div className="row gutters">
              {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h6 className="mb-2 text-primary">Détails personnels</h6>
            </div> */}
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="fullName">
                    {t("Cin")}<span className="text-danger">*</span>
                  </label>
                  <input type="text" required className="form-control" value={userdata.cin} onChange={(e) => handlechange('cin', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("CIN Délivré le")}<span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="date" value={userdata.delivre_le} onChange={(e) => handlechange('delivre_le', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("CIN Délivré a")}<span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" value={userdata.delivre_a} onChange={(e) => handlechange('delivre_a', e.target.value)} />
                </div>
              </div>

              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Résident")}<span className="text-danger">*</span>
                  </label>
                  <select className="form-control" value={userdata.resident} onChange={(e) => handlechange('resident', e.target.value)} >
                    <option value="oui">
                      {t('oui')}
                    </option>
                    <option value="non">
                      {t('non')}
                    </option>
                  </select>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group">
                  <label>{t("Num passport")}</label>
                  <input className="form-control" type="text" value={userdata.num_passport} onChange={(e) => handlechange('num_passport', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Num_carte_sejour")}
                  </label>
                  <input className="form-control" type="text" value={userdata.num_carte_sejour} onChange={(e) => handlechange('num_carte_sejour', e.target.value)} />

                </div>
              </div>
           
            </div>
            <div className="row gutters">

                <>
                <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Fonction")}
                  </label>
                  {/*<select className="form-control" type="text" value={userdata.fonction} onChange={(e) => handlechange('fonction', e.target.value)} >
                    {list_fonction.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))

                    }
                  </select>*/}
                  <input type="text" required className="form-control" value={userdata.fonction} onChange={(e) => handlechange('fonction', e.target.value)} />

                </div>
              </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label>{t("Numéro de CNSS")}</label>
                      <input className="form-control" type="text" value={userdata.cnss} onChange={(e) => handlechange('cnss', e.target.value)} />
                    </div>
                  </div>
                  {( types_contrat?.find(item => item.code === userdata.type_contrat)?.cnss === 1)  && <div className="col-2">
                    <div className="form-group">
                      <label>{t("taux cnss")}</label>
                      <input className="form-control" type="text" value={userdata.taux_cnss} onChange={(e) => handlechange('taux_cnss', e.target.value)} />
                    </div>
                  </div>}

                </>
              
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Type contrat")}<span className="text-danger">*</span>
                  </label>
                  <select value={userdata.type_contrat} onChange={(e) => handlechange('type_contrat', e.target.value)} className="selectpicker form-control">
                    <option value="">{t("choisir type de contrat")}</option>
                    {types_contrat && types_contrat.map((item) => (
                      <option value={item?.code}>{t(item?.libelle)}</option>

                    ))

                    }

                  </select>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Date d'embauche")}
                  </label>
                  <input className="form-control" type="date" value={userdata.date_embauche} onChange={(e) => handlechange('date_embauche', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Date fin contrat")}
                  </label>
                  <input className="form-control" type="date"
                    value={userdata.date_fincontrat} onChange={(e) => handlechange('date_fincontrat', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Departement")}
                  </label>

                  <Select
                    value={userdata.departement}
                    onChange={(e) => handlechange('departement', e)}
                    isMulti
                    name="departement"
                    options={alldep}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />

                </div>
              </div>

            </div>

            <div className="row gutters">
              {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h6 className="mb-2 text-primary">Détails personnels</h6>
            </div> */}

              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="fullName">
                    {t("situation familiale ")}
                  </label>
                  <select className="form-control" value={userdata.situation_familiale} onChange={(e) => handlechange('situation_familiale', e.target.value)}>
                    <option value=''>{t('choisir')} </option>
                    {list_situation_familiale.map((item, index) => (
                      <option key={index} value={item}>
                        {t(item)}
                      </option>
                    ))}


                  </select>
                </div>
              </div>
              {userdata.situation_familiale !== "celibataire" &&
                <div className="col-2">
                  <div className="form-group">
                    <label htmlFor="fullName">
                      {t("Chef Famille")}
                    </label>
                    <select className="form-control" value={userdata.chef_famille} onChange={(e) => handlechange('chef_famille', e.target.value)} >
                      <option value={1}>
                        {t('oui')}
                      </option>
                      <option value={0}>
                        {t('non')}
                      </option>
                    </select>
                  </div>
                </div>}

              {userdata.situation_familiale !== 'celibataire' &&
                <><div className="col-2">
                  <div className="form-group">
                    <label>
                      {t("Nombre enfant")}
                    </label>
                    <input className="form-control" type="text" onKeyPress={handleKeyPress_number} value={userdata.enfant} onChange={(e) => handlechange('enfant', e.target.value)} />

                  </div>
                </div><div className="col-2">
                    <div className="form-group">
                      <label>
                        {t("Nbr enfant handicapé")}
                      </label>
                      <input className="form-control" type="text" value={userdata.enfant_handicape} onChange={(e) => handlechange('enfant_handicape', e.target.value)} />

                    </div>
                  </div><div className="col-2">
                    <div className="form-group">
                      <label>
                        {t("Nbr enfant non boursier")}
                      </label>
                      <input className="form-control" type="text" value={userdata.enfant_N_B} onChange={(e) => handlechange('enfant_N_B', e.target.value)} />

                    </div>
                  </div></>}

              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Parent a charge")}
                  </label>
                  <select className="form-control" value={userdata.Parent_charge} onChange={(e) => handlechange('Parent_charge', e.target.value)}  >
                    <option value={1}>
                      {t('oui')}
                    </option>
                    <option value={0}>
                      {t('non')}
                    </option>
                  </select>

                </div>
              </div>
            </div>
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{/* <h6 className="mt-3 mb-2 text-primary">Address</h6> */}</div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Salaire de base")}
                  </label>
                  <input className="form-control" type="text" onKeyPress={handleKeyPress_number} value={userdata.salaire_base} onChange={(e) => handlechange('salaire_base', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Salaire net")}
                  </label>
                  <input className="form-control" onKeyPress={handleKeyPress_number} type="text" value={userdata.salaire_net} onChange={(e) => handlechange('salaire_net', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Salaire brut")}
                  </label>
                  <input className="form-control" onKeyPress={handleKeyPress_number} type="text" value={userdata.salaire_brut} onChange={(e) => handlechange('salaire_brut', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Nbr salaire par ans")}
                  </label>
                  <input className="form-control" type="text" value={userdata.nbr_salaire} onChange={(e) => handlechange('nbr_salaire', e.target.value)} />
                  {nbr_salire_alert !== null && (<p style={{ color: 'red' }}>{nbr_salire_alert}</p>)}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Nbr jours par mois")}
                  </label>
                  <input className="form-control" type="text" value={userdata.nb_jr_mois} onChange={(e) => handlechange('nb_jr_mois', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("Nbr d'heures par ans")}
                  </label>
                  <input className="form-control" type="text" value={userdata.nbr_heure_mois} onChange={(e) => handlechange('nbr_heure_mois', e.target.value)} />
                </div>
              </div>


            </div>
            <div className="row gutters">

              <div className="col-2">
                <div className="form-group">
                  <label>
                    {t("assurance de groupe")}
                  </label>
                  <input type="checkbox" checked={userdata.assurance_groupe} onChange={(e) => handlechange('assurance_groupe', userdata.assurance_groupe === 1 ? 0 : 1)} />
                </div>
              </div>
              {userdata.assurance_groupe === 1 &&
                <div className="col-2">
                  <div className="form-group">
                    <label>
                      {t(" valeur AG")}
                    </label>
                    <input type="text" value={userdata.val_assurance_groupe !== null ? userdata.val_assurance_groupe : param_defaut?.val_assurance_G} onChange={(e) => handlechange('val_assurance_groupe', e.target.value)} />
                  </div>
                </div>}

              <div className="col-2">
                <div className="form-group">
                  <label>{t("Bank")}</label>
                  <input className="form-control" type="text" value={userdata.bank} onChange={(e) => handlechange('bank', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>{t("Agence")}</label>
                  <input className="form-control" type="text" value={userdata.agence} onChange={(e) => handlechange("agence", e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>{t("RIB")}</label>
                  <input className="form-control" type="text" value={userdata.rib} onChange={(e) => handlechange('rib', e.target.value)} />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label>{t("Mode de paiement")}</label>
                  <select className="form-control" value={userdata.mode_paiement} onChange={(e) => handlechange("mode_paiement", e.target.value)}>
                    {mode_paiement.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}

                  </select>
                </div>
              </div>
              <div>
              <div className="form-group">
                  <label>{t("Matricule ")}</label>
                  <input className="form-control" type="text" value={userdata.matricule_user} onChange={(e) => handlechange('matricule_user', e.target.value)} />
                </div>
              </div>

            </div>

            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"> <h3>{t("Rubriques")}</h3>
                {list_rubriques.length === 0 && <a to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                  <i className="fa fa-plus" />
                </a>}</div>

            </div>
            <div className="row mt-5">
              <strong className="col-4">
                {t('Rubrique')}
              </strong>
              <strong className='col-1 ml-5'>
                {t('Valeur mensuel')}
              </strong>
              <strong className='col-1 ml-5'>
                {t('Valeur annuel')}
              </strong>
              <strong className='col-1 ml-5'>
                {t('Permanent')}
              </strong>




            </div>

            {list_rubriques.map((item, i) => (
              <div className="row mt-5">
                <select className="form-control col-4" name='rub_code' value={item.rub_code} onChange={(e) => handlechangligne(e, i)}>
                  <option value=''>{t('choisir ')}</option>
                  {allrubriques.map(item => (
                    <option key={item.id} value={item.code}>
                      {item.libelle} - {item.type}
                    </option>
                  ))}
                </select>
                <input type="text" onKeyPress={handleKeyPress_number} placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_mensuel' value={item.valeur_mensuel} onChange={(e) => handlechangligne(e, i)} />
                <input type="text" onKeyPress={handleKeyPress_number} placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_annuel' value={item.valeur_annuel} onChange={(e) => handlechangligne(e, i)} />


                <input type="checkbox" className='col-1 ml-2' name='permanent' checked={item.permanent} onClick={(e) => handlechangligne(e, i)} />

                {item.permanent === 0 &&
                  <>
                    <input type="date" className='col- ml-2' name='date_debut' value={moment(new Date(item.date_debut)).format("YYYY-MM-DD")} onChange={(e) => handlechangligne(e, i)} />

                    <input type="date" className='col- ml-2' name='date_fin' value={moment(new Date(item.date_fin)).format("YYYY-MM-DD")} onChange={(e) => handlechangligne(e, i)} />

                  </>}
                <div className=" col-1">

                  {list_rubriques.length - 1 === i && (
                    <a to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                      <i className="fa fa-plus" />
                    </a>
                  )}

                  <a to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                    <i className="fa fa-minus" />
                  </a>

                </div>
              </div>
            ))}
            <div className="row gutters mt-3">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"> <h3>{t("Avantage")}</h3>
                {list_avantages_user.length === 0 && <a to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick_avantage}>
                  <i className="fa fa-plus" />
                </a>}</div>


            </div>
            <div className="row">
              <strong className="col-4">
                {t('Rubrique')}
              </strong>
              <strong className='col-1 ml-5'>
                {t('Valeur mensuel')}
              </strong>
              <strong className='col-1 ml-5'>
                {t('Valeur annuel')}
              </strong>
              <strong className='col-1 ml-5'>
                {t('Permanent')}
              </strong>




            </div>

            {list_avantages_user.map((item, i) => (
              <div className="row ">
                <select className="form-control col-4" name='avantage_code' value={item.avantage_code} onChange={(e) => handlechangligne_avantage(e, i)}>
                  <option value=''>{t('choisir ')}</option>
                  {list_avantages.map(item => (
                    <option key={item.id} value={item.code}>
                      {item.libelle}
                    </option>
                  ))}
                </select>
                <input type="text" onKeyPress={handleKeyPress_number} placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_mensuel' value={item.valeur_mensuel} onChange={(e) => handlechangligne_avantage(e, i)} />
                <input type="text" onKeyPress={handleKeyPress_number} placeholder="valeur d'avantage" className='col-1 ml-5' name='valeur_annuel' value={item.valeur_annuel} onChange={(e) => handlechangligne_avantage(e, i)} />

                <input type="checkbox" className='col-1 ml-2' name='permanent' checked={item.permanent} onClick={(e) => handlechangligne_avantage(e, i)} />

                {item.permanent === 0 &&
                  <>
                    <input type="date" placeholder="date debut" className='col- ml-2' name='date_debut' value={item.date_debut !== null && moment(new Date(item.date_debut)).format("YYYY-MM-DD")} onChange={(e) => handlechangligne_avantage(e, i)} />

                    <input type="date" placeholder="date fin" className='col- ml-2' name='date_fin' value={item.date_fin !== null && moment(new Date(item.date_fin)).format("YYYY-MM-DD")} onChange={(e) => handlechangligne_avantage(e, i)} />

                  </>}
                <div className=" col-1">

                  {list_avantages_user.length - 1 === i && (
                    <a to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick_avantage}>
                      <i className="fa fa-plus" />
                    </a>
                  )}

                  <a to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick_avantage(i)}>
                    <i className="fa fa-minus" />
                  </a>

                </div>
              </div>
            ))}
            <Avantage_fiscaux add_av_fiscaux={add_av_fiscaux} setadd_av_fiscaux={setadd_av_fiscaux} setlist_av_fiscaux={setlist_av_fiscaux} list_av_fiscaux={list_av_fiscaux} alllist_av_fiscaux={alllist_av_fiscaux} userId={userId} param_defaut={param_defaut} userdata={userdata} setuserdata={setuserdata} t={t} exercices_defaut={exercices_defaut} />

            <div className="card-body">
              <div className="card-header  border-0">
                <h4 className="card-title">{t("calandrier")}</h4>
              </div>
              <table className="table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">
                <thead>
                  {Object.keys(userdata.calandrier).map(key => (
                    <th key={key} >
                      {key}

                    </th>

                  ))}

                </thead>
                <tbody>
                  <tr>
                    {Object.keys(userdata.calandrier).map(key => (
                      <><td key={key}>
                        <input type='text' value={userdata.calandrier[key]} onChange={(e) => handelchange_calandrier(key, parseFloat(e.target.value))} />
                        {calandrier_msg && key === "Janvier" && <p onClick={remplirCalandrier}>{t('remplir les champs avec le meme valeur')}</p>}
                      </td>


                      </>

                    ))}
                  </tr>
                </tbody>

              </table>

            </div>
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="text-right">
                  {/* <button type="button" id="submit" name="submit" className="btn btn-secondary">
                      Cancel
                    </button> */}
                  <button type="button" id="submit" name="submit" className="btn btn-primary" onClick={handleSubmitPersonnel}>
                    {t("Enregistrer")}
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/* <div className="row gutters">
              <div>
                {loading && (
                  <div className="container mt-5">
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <CustomSyncLoader></CustomSyncLoader>
                    </div>
                  </div>
                )}
                {!loading && error && (
                  <div>
                    <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>
                  </div>
                )}
                {!loading && !error && documentData && (
                  <PersonnelDoc idUser={userId}></PersonnelDoc>
                )}
              </div>
            </div> */}
        </div>
      )}
    </>
  );
}

export default CardPerso;
