import React, { useEffect, useState } from "react";
import { editUserProfile, getUserById } from "../../Services/Pointeuse/UsersApi";
import ImageComponent from "../Styles/ImageComponent";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import CardPerso from "./CardPerso";
import { getRetardByUserId } from "../../Services/Pointeuse/RetardApi";
import { getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { getAutorisationByUserId } from "../../Services/Pointeuse/AutorisationApi";
import { getCongeByUserCodeId, getCountCongeByUserCodeId, getsoldecongeuser, updatesoldecongeuser } from "../../Services/Pointeuse/CongeApi";
import { filterTodayPointage, filterYearAutorisation, filterYearDeplacement, filterYearQuestionnaire } from "../../Utils/userDetaiUtils";
import { getQuestionnaireByUserId } from "../../Services/Pointeuse/QuestionnaireApi";
import { getDepalacementByUserId } from "../../Services/Pointeuse/DeplacementApi";
import RetardChart from "../Charts/CustumRecharts/AreaChartRecharts";
import RetardRechartsChart from "../Charts/CustumRecharts/AreaChartRecharts";
import A from "../Charts/CustumApex/BarChartApex";
import CongeRechartChart from "../Charts/CustumRecharts/BarChartRecharts";
import CardDocument from "./CardDocument";
import BarChartApex from "../Charts/CustumApex/BarChartApex";
import AreaChartApex from "../Charts/CustumApex/AreaChartApex";
import ApexBar2 from "../Charts/apexcharts/Bar2";
import AreaApexConge from "../Charts/CustumApex/AreaApexConge";
import AddContrat from "../Contrat/AddContrat";
import CardOtherDocument from "./CardOtherDocument";
import AddNewUserTime from "../UserTime/AddNewUserTime";
import PointageTableMonth from "../Pointages/PointageTableMonth";
import ListVirementUser from "../Virement/ListVirementUser";
import { Button, Modal } from "react-bootstrap";
import { addNumberToDate } from "../../Utils/DateUtils";
import { t } from "i18next";
import { getparam_paie_active } from "../../Services/paie/parametrage_paie";
import { getExercice } from "../../Services/Pointeuse/EntrepriseApi";
import { handleKeyPress_number } from "../../Utils/InputUtils";
import { toast } from "react-toastify";
import QuittanceDeSolde from "./QuittanceDeSolde";

function EditUser(props) {
   const [userdata, setuserdata] = useState({
      cin: null,
      delivre_a:'',
      fonction: 'dev',
      delivre_le: null,
      cnss: null,
      type_contrat: '',
      date_embauche: '',
      date_fincontrat: '',
      famille: '',
      enfant: 0,
      adresse: '',
      salaire_base: "",
      rib: '',
      bank: '',
      agence: '',
      taux_cnss: null,
      resident: 'oui',
      num_passport: '',
      assurance_groupe: 0,
      val_assurance_groupe: null,
      mode_paiement: 'cache',
      salaire_brut: '',
      salaire_net: '',
      Parent_charge: 1,
      situation_familiale: '',
      num_carte_sejour: '',
      nbr_salaire: '',
      nbr_heure_mois: 0,
      nb_jr_mois: 0,
      chef_famille: 0,
      matricule_user:null,
      calandrier: {
        Janvier: 0,
        février: 0,
        mars: 0,
        avril: 0,
        mai: 0,
        juin: 0,
        juillet: 0,
        août: 0,
        septembre: 0,
        octobre: 0,
        novembre: 0,
        décembre: 0
      }
  
    })
      const [types_contrat, settypes_contrat] = useState([]);
    
  const [birthDate, setbirthDate] = useState("");
  const [codeGenerated, setcodeGenerated] = useState("");
  const [email, setemail] = useState("");
  const [fonction, setfonction] = useState("");
  const [id, setid] = useState(null);
  const [isactif, setisactif] = useState("");
  const [mobile, setmobile] = useState("");
  const [nom, setnom] = useState("");
  const [prenom, setprenom] = useState("");
  const [roleCode, setroleCode] = useState("");
  const [soldeConge, setsoldeConge] = useState("");
  const [img, setimg] = useState("");
  const [nationalite, setnationalite] = useState("");
  const [adresse, setadresse] = useState("");
  const [longitude, setlongitude] = useState("");
  const [latitude, setlatitude] = useState("");

  const [retards, setretards] = useState(null);
  const [pointages, setpointages] = useState(null);
  const [conges, setConges] = useState(null);
  const [deplacements, setdeplacements] = useState(null);
  const [autorisations, setautorisation] = useState(null);
  const [questionnaire, setquestionnaire] = useState(null);
  const [contConge, setcontConge] = useState("0");
  const [totalAutorisation, settotalAutorisation] = useState(null);
  const [totalQuestionnaire, settotalQuestionnaire] = useState(null);
  const [totalConge, settotalConge] = useState(null);
  const [totalDeplacement, settotalDeplacement] = useState(null);
  const [coeffConge, setcoeffConge] = useState(null);
  const [user, setuser] = useState(null);
  const [parametrage_societe, setparametrage_societe] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [id_exercice, setid_exercice] = useState()
  const [exercice, setexercice] = useState([])
  const [solde_debut,setsoldedebut]=useState()
  const [modifconge,setmodifconge]=useState(false)
  const [dataconge,setdataconge]=useState({
    id:'',
    code_user:props.match.params.id,
    code_exercie:'',
    solde_debut:0,
    acquis:0,
    servis:0,
    solde:0,
    code_generated:localStorage.getItem('code_generated')
  })

  useEffect(() => {

    getExercice().then((res) => {
      if (Array.isArray(res?.data)) {
        setexercice(res?.data);
        const copiedata = {...dataconge}
        copiedata.code_exercie =res?.data[0]?.id;
        setdataconge(copiedata)
        

      }

    });
  }, [])
 
  useEffect(()=>{
    
    if(dataconge.code_exercie!==''){
 
      getsoldecongeuser(dataconge.code_exercie,props.match.params.id).then(res=>{
        
          if(res.data.length!==0){
            setdataconge({
              id:res.data[0].id,
              code_user:props.match.params.id,
              code_exercie:res.data[0].code_exercie,
              solde_debut:res.data[0].solde_debut,
              acquis:res.data[0].acquis,
              servis:res.data[0].servis,
              solde:res.data[0].solde,
              code_generated:localStorage.getItem('code_generated')

            })
          }
      })
    }
    
  },[dataconge.code_exercie,props])
  useEffect(() => {
    getUserById(props.match.params.id).then((res) => {

      setimg(res.data.img);
      setbirthDate(res.data.birth_date);
      setcodeGenerated(res.data.code_generated);
      setemail(res.data.email);
      setfonction(res.data.fonction);
      setid(res.data.id);
      setisactif(res.data.isactif);
      setmobile(res.data.mobile);
      setnom(res.data.nom);
      setprenom(res.data.prenom);
      setroleCode(res.data.role_code);
      setsoldeConge(res.data.solde_conge);
      setcoeffConge(res.data.coefficient_conge);
      setnationalite(res.data.nationalite);
      setadresse(res.data.adresse)
      setlongitude(res.data.longitude);
      setlatitude(res.data.latitude)
      setuser(res.data);
    });
  }, []);

  useEffect(() => {
    getRetardByUserId(id).then((res) => {
      setretards(res.data);
    });
  }, [id]);
  useEffect(() => {
    getparam_paie_active().then((res) => {
      if (res.status === 200) {
        setparametrage_societe(res.data.data[0])
      }
    })
  }, [])
  useEffect(() => {
    getPointageByUserId(id).then((res) => {
      console.log("todayPointage",res)

     if(res){
      var todayPointage = filterTodayPointage(res.data);
      setpointages(todayPointage);
     } 
    });
  }, [id]);

  useEffect(() => {
    getCongeByUserCodeId(id).then((res) => {
      settotalConge(res.data);
      var yearAutorisation = filterYearAutorisation(res.data);
      setConges(yearAutorisation);
    });
  }, [id]);

  useEffect(() => {
    getAutorisationByUserId(id).then((res) => {
      settotalAutorisation(res.data);
      var yearAutorisation = filterYearAutorisation(res.data);
      setautorisation(yearAutorisation);
    });
  }, [id]);
  useEffect(() => {
    getDepalacementByUserId(id).then((res) => {
      settotalDeplacement(res.data);
      var yearDeplacement = filterYearDeplacement(res.data);
      setdeplacements(yearDeplacement);
    });
  }, [id]);

  useEffect(() => {
    getQuestionnaireByUserId(id).then((res) => {
      settotalQuestionnaire(res.data);
      var yearQuestionnaire = filterYearQuestionnaire(res.data);
      setquestionnaire(yearQuestionnaire);
    });
  }, [id]);

  useEffect(() => {
    getCountCongeByUserCodeId(id).then((res) => {

      var key = Object.entries(res.data[0]);
      key[0][1] && setcontConge(key[0][1]);
    });
  }, [id]);

  /**
   * It modifies the user profile.
   */
  const handleSubmitFom = () => {
    swal({
      title: "Modification",
      text: t("Voulez vous vraiment modifier cet enregistrement!"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        editUserProfile(props.match.params.id, nom, prenom, fonction, mobile, coeffConge, nationalite, adresse, longitude, latitude).then((res) => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          //getData();
        });
      }
    });
  };
  const handelchange_dataconge = (name,value)=>{
    const copiedata={...dataconge}
    copiedata[name]= value ; 
    copiedata.solde= parseFloat(copiedata.solde_debut)+parseFloat(copiedata.acquis)-parseFloat(copiedata.servis)
    setdataconge(copiedata)
  }
  const savesoldeconge =async ()=>{
    const copie = {...dataconge}
    copie.solde_debut=parseFloat(copie.solde_debut)
    copie.acquis=parseFloat(copie.acquis)
    copie.servis=parseFloat(copie.servis)
    if(copie.id === '') {
  
      delete copie.id
    }
    const resp = await updatesoldecongeuser(copie)
    if(resp.status === 200){

      toast.success('donnee enregistré')
      setmodifconge(false)
    }else{
      toast.error('probleme')
    }
  }
  return (
    <div>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Profile")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="d-flex align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <a href="#" className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                {" "}
                <i className="feather feather-mail" />{" "}
              </a>
              <a href="#" className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                {" "}
                <i className="feather feather-phone-call" />{" "}
              </a>
              <a href="#" className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                {" "}
                <i className="feather feather-info" />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*End Page header*/}
      {/* Row */}
      <div className="row">
        <div className="col-xl-3 col-md-12 col-lg-12">
          <div className="card user-pro-list overflow-hidden">
            <div className="card-body">
              <div className="user-pic text-center">
                <ImageComponent atr={"avatar avatar-xxl brround"} picture={img}></ImageComponent>
                {/* <span className="avatar-Statut bg-green" /> */}

                <div className="pro-user mt-3">
                  <h5 className="pro-user-username text-dark mb-1 fs-16">
                    {nom} {prenom}
                  </h5>
                  <h6 className="pro-user-desc text-muted fs-12">{email}</h6>

                  <div className="btn-list">
                    {/* <a href="#" className="btn btn-light">
                      {" "}
                      <i className="feather feather-edit" />{" "}
                    </a> */}
                    <a href="#" className="btn btn-light">
                      {" "}
                      <i className="feather feather-mail" />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-0">
              <div className="row">
                <Link to="/admin/questionnaire" className="col-4 text-center py-5 border-right">
                  <h5 className="fs-12 font-weight-semibold mb-3">{t("Cette année")}</h5>
                  <h5 className="mb-2">
                    <span className="fs-18 text-success">{questionnaire?.length}</span>
                  </h5>
                  <h5 className="fs-12 mb-0"> {t("Questionnaires")}</h5>
                </Link>
                <Link to="#" className="col-4  py-5 text-center border-right">
                  <h5 className="fs-12 font-weight-semibold mb-3">{t("Cette année")}</h5>
                  <h5 className="mb-2">
                    <span className="fs-18 text-orange">{conges?.reduce((acc, item) => { return acc + item.nbr_jour }, 0)}</span>
                  </h5>
                  <h5 className="fs-12 mb-0"> {t("Congé")}</h5>
                </Link>
                <Link to="/admin/autorisation" className="col-4 text-center py-5">
                  <h5 className="fs-12 font-weight-semibold mb-3">{t("Cette année")}</h5>
                  <h5 className="mb-2">
                    <span className="fs-18 text-primary">{autorisations?.length}</span>
                  </h5>
                  <h5 className="fs-12 mb-0"> {t("Autorisation")}</h5>
                </Link>
              </div>
            </div>
          </div>
          <div className="card">

            <div className="card-body pt-0">
              <div className="table-responsive">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">{t("Collaborateur ID")}</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>#{id}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">{t("Désignation")}</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>{fonction}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">{t("Département")}</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>-</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">{t("N° de téléphone")}</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        <span>{mobile}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-0">
                        <span className="w-50">{t("Statut")}</span>
                      </td>
                      <td>:</td>
                      <td className="py-2 px-0">
                        {isactif == "1" ? (
                          <span className="badge badge-success">{t("Active")}</span>
                        ) : (
                          <span className="badge badge-danger">{t("Non active")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header  border-0">
              <div className="card-title">{t("Congé")} {new Date().getFullYear() - 1}</div>
            </div>
            <div className="card-body">
              <AreaApexConge id={id}></AreaApexConge>
            </div>
            <div className="card-body row">
              <h1>Solde congé</h1>
              </div>
            <div className="card-body ">
              <div className="row">
              <div className="col-4">
                <label>{t('Code Exercice')}</label>

                <select className="form-control" value={dataconge.code_exercie} onChange={(e) => handelchange_dataconge('code_exercie',e.target.value)}>
                  {exercice && exercice?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.code_exercice}
                    </option>
                  ))}


              </select>
              </div>
              <div className="col-4">
              <label>{t('Solde ')}</label>


                  <input type='text' readOnly={true} className="form-control" value={dataconge.solde}  onKeyPress={handleKeyPress_number} onChange={(e)=>handelchange_dataconge('solde',e.target.value)}></input>
              </div>
              <div className="col-4">
                  <button className="btn btn-primary mt-5" onClick={()=>!modifconge? setmodifconge(!modifconge):savesoldeconge()}>{!modifconge?'modifier':'enregistrer'}</button>
              </div>
              </div>
              {modifconge &&
              <div className="row">
              <div className="col-4">
            <label>{t('Solde debut')}</label>


                <input type='text' className="form-control" value={dataconge.solde_debut}  onKeyPress={handleKeyPress_number} onChange={(e)=>handelchange_dataconge('solde_debut',e.target.value)}></input>
            </div>
              <div className="col-4">
            <label>{t('acquis')}</label>


                <input type='text'  className="form-control" value={dataconge.acquis}  onKeyPress={handleKeyPress_number} onChange={(e)=>handelchange_dataconge('acquis',e.target.value)}></input>
            </div>
            <div className="col-4">
            <label>{t('servis')}</label>


                <input type='text'  className="form-control" value={dataconge.servis}  onKeyPress={handleKeyPress_number} onChange={(e)=>handelchange_dataconge('servis',e.target.value)}></input>
            </div>
        
        </div>}
              
              
             
            </div>

          </div>
        </div>
        <div className="col-xl-9 col-md-12 col-lg-12">
          <div className="row">
            <Link to="#" onClick={handleShow} className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">{t("Total Congés")}</span>
                          <h3 className="mb-0 mt-1 text-danger fs-25">{totalConge?.reduce((acc, item) => { return acc + item.nbr_jour }, 0)}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-danger-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-briefcase" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
            <Link to="/admin/autorisation" className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">{t("Total Autorisations")}</span>
                          <h3 className="mb-0 mt-1 text-primary  fs-25">{totalAutorisation?.length}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-primary-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-clipboard" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
            <Link to="#" className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">{t("Total Déplacement")}</span>
                          <h3 className="mb-0 mt-1 text-secondary fs-25">{totalDeplacement?.length}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-secondary-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-info" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
            <Link to="/admin/questionnaire" className="col-xl-3 col-lg-6 col-md-12">
              <div className="card">
                <a href="#">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-8">
                        <div className="mt-0 text-left">
                          <span className="fs-16 font-weight-semibold">{t("Total Questionnaire")}</span>
                          <h3 className="mb-0 mt-1 text-success fs-25">{totalQuestionnaire?.length}</h3>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="icon1 bg-success-transparent my-auto  float-right">
                          {" "}
                          <i className="feather feather-check" />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Link>
          </div>

          <div className="row">
            <div className="col-xl-12 col-md-12 col-lg-12">
              <div className="tab-menu-heading hremp-tabs p-0 ">
                <div className="tabs-menu1">
                  {/* Tabs */}
                  <ul className="nav panel-tabs">
                    <li >
                      <a href="#tab5" className="active" data-toggle="tab">
                        {t("Paramétrage-paie")}
                      </a>
                    </li>
                  
                    <li>
                      <a href="#tab7" data-toggle="tab">
                        {t("Détails Collaborateur")}
                      </a>
                    </li>

                    <li>
                      <a href="#tab8" data-toggle="tab">
                        {t("Documents")}
                      </a>
                    </li>
                    <li>
                      <a href="#tab9" data-toggle="tab">
                        {t("Contrat")}
                      </a>
                    </li>
                    <li>
                      <a href="#tab6" data-toggle="tab">
                        {t("Autre document")}
                      </a>
                    </li>
                    <li>
                      <a href="#tab1" data-toggle="tab">
                        {t("Heure de travail")}
                      </a>
                    </li>
                    <li>
                      <a href="#tab13" data-toggle="tab">
                        {t("Fiche du pointage")}
                      </a>
                    </li>
                    <li>
                      <a href="#tab14" data-toggle="tab">
                        {t("Historique Salaire")}
                      </a>
                    </li>
                    <li >
                      <a href="#tab15"  data-toggle="tab">
                        {t("QUITTANCE DE SOLDE ")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="panel-body tabs-menu-body hremp-tabs1 p-0">
                <div className="tab-content">
                  <div className="tab-pane active" id="tab5">
                    <CardPerso 
                    types_contra={types_contrat}
                    settypes_contrat={settypes_contrat}
                    userId={props.match.params.id} 
                    userdata={userdata} 
                    setuserdata={setuserdata}></CardPerso>
                  </div>
                  <div className="tab-pane" id="tab6">
                    <div className="card-body">
                      <CardOtherDocument userId={props.match.params.id} />
                    </div>
                  </div>
                  <div className="tab-pane" id="tab7">
                    <div className="card-body">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">{t("Nom")}</label>
                          </div>
                          <div className="col-md-9">
                            <input value={nom} onChange={(e) => setnom(e.target.value)} type="text" className="form-control" placeholder={t("Nom")} />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">{t("Prénom")}</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={prenom}
                              onChange={(e) => setprenom(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder={t("Prénom")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">{t("Email")}</label>
                          </div>
                          <div className="col-md-9">
                            <input disabled value={email} type="email" className="form-control" placeholder={t("Email")} />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">{t("Fonction")}</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={fonction}
                              onChange={(e) => setfonction(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="Fonction"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">{t("Date de naissance")}</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={birthDate}
                              disabled
                              onChange={(e) => setbirthDate(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="date de naissance"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              {t("Mobile")}
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={mobile}
                              onChange={(e) => setmobile(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="Mobile"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              {t("Coefficient de congé")}
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={coeffConge}
                              onChange={(e) => setcoeffConge(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="1.1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              {t("Nationalité")}
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={nationalite}
                              onChange={(e) => setnationalite(e.target.value)}
                              type="text"
                              className="form-control"

                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              {t("Adresse")}
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={adresse}
                              onChange={(e) => setadresse(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              {t("longitude")}
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={longitude}
                              onChange={(e) => setlongitude(e.target.value)}
                              type="text"
                              className="form-control"

                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-3">
                            <label className="form-label mb-0 mt-2">
                              {t("latitude")}
                              {/* <span
                                className="form-help"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Bank Identify Number in your Country"
                              >
                                ?
                              </span> */}
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              value={latitude}
                              onChange={(e) => setlatitude(e.target.value)}
                              type="text"
                              className="form-control"

                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-right">
                        <Link to="#" className="btn btn-primary mr-2" onClick={handleSubmitFom}>
                          {t("Enregistrer")}
                        </Link>
                        {/* <a href="#" className="btn btn-danger">
                          Cancle
                        </a> */}
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane" id="tab8">
                    <CardDocument idUser={props.match.params.id}></CardDocument>
                  </div>
                  <div className="tab-pane" id="tab9">
                    <AddContrat idUser={props.match.params.id} />
                  </div>
                  <div className="tab-pane" id="tab1">
                    <AddNewUserTime idUser={props.match.params.id}></AddNewUserTime>
                  </div>
                  <div className="tab-pane" id="tab13">
                    {user && <PointageTableMonth user={user}></PointageTableMonth>}
                  </div>
                  <div className="tab-pane" id="tab14">
                    <ListVirementUser userId={props.match.params.id}></ListVirementUser>
                  </div>
                  <div className="tab-pane" id="tab15">
                    <QuittanceDeSolde t={t} exercice={exercice} types_contrat={types_contrat} perso={userdata} userId={props.match.params.id} conges={dataconge} user={user}></QuittanceDeSolde>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Congé {new Date().getFullYear()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <table className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer">
              <thead>
                <tr>
                  <th className="border-bottom-0 w-5">{t("motif congé")}</th>
                  <th className="border-bottom-0 w-5">{t("De")}</th>
                  <th className="border-bottom-0 w-5">{t("à")}</th>
                  <th className="border-bottom-0 w-4">{t("Nb-J")}</th>
                </tr>
              </thead>
              <tbody>
                {conges &&
                  conges.map((el) => (
                    <tr key={el.id}>
                      <td>{el.motif}</td>

                      <td>{el.date.split(" ")[0]}</td>
                      <td>{addNumberToDate(el.nbr_jour, el.date)}</td>
                      <td>{el.nbr_jour}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Fermer")}
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditUser;
